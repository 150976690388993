import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import { FormattedMessage } from "react-intl";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import Layout from "../Layout";
import Footer from "../Footer";
import Extra from "../Extra";
import StructuredText from "../StructuredText";
import localeSlug from "../../utils/localeSlug";
import LocaleContext from "../../context/locale";

function Work({ location, data }) {
  const { langKey } = useContext(LocaleContext);
  const {
    id,
    title,
    brand,
    challenge,
    idea,
    results,
    isSpot,
    youtubeVideo,
    youtubeVideo2,
    youtubeVideo3,
    youtubeVideo4,
  } = data.work;
  const { extraVideos, extraImages } = data.extra;
  const works = data.works.nodes;

  const ideaText = toPlainText(idea);
  const resultsText = toPlainText(results);

  return (
    <Layout location={location} languages={data.site.siteMetadata.languages}>
      <div
        className="container mx-auto pt-24 px-9 lg:px-0 text-white"
        style={{ marginTop: 70 }}
      >
        <div className="mb-20 text-center">
          <h1 className="text-4xl">{title}</h1>
          <p className="mt-4">{brand}</p>
        </div>
      </div>
      <div className="py-24 bg-white text-gray-500">
        <div className="container mx-auto px-9 lg:px-0">
          {youtubeVideo && (
            <>
              {isSpot ? (
                <h2 className="text-3xl text-center mb-4">
                  <FormattedMessage id="detailSpot" />
                </h2>
              ) : (
                <h2 className="text-3xl text-center mb-4">
                  <FormattedMessage id="detailCase" />
                </h2>
              )}
              <iframe
                title="youtube"
                className="mb-10"
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${youtubeVideo.providerUid}?rel=0&amp;controls=0&amp;showinfo=0`}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </>
          )}

          {youtubeVideo2 && (
            <iframe
              title="youtube2"
              className="mb-10"
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${youtubeVideo2.providerUid}?rel=0&amp;controls=0&amp;showinfo=0`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          )}

          {youtubeVideo3 && (
            <iframe
              title="youtube3"
              className="mb-10"
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${youtubeVideo3.providerUid}?rel=0&amp;controls=0&amp;showinfo=0`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          )}

          {youtubeVideo4 && (
            <iframe
              title="youtube4"
              className="mb-10"
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${youtubeVideo4.providerUid}?rel=0&amp;controls=0&amp;showinfo=0`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          )}

          <div className="mb-8">
            {challenge && (
              <>
                {(ideaText || resultsText) && (
                  <h3 className="text-2xl mb-4">
                    <FormattedMessage id="detailChallenge" />
                  </h3>
                )}
                <div className="mb-5">
                  <StructuredText data={challenge} />
                </div>
              </>
            )}

            {ideaText && (
              <>
                <h3 className="text-2xl mb-4">
                  <FormattedMessage id="detailIdea" />
                </h3>
                <div className="mb-5">
                  <StructuredText data={idea} />
                </div>
              </>
            )}

            {resultsText && (
              <>
                <h3 className="text-2xl mb-4">
                  <FormattedMessage id="detailResults" />
                </h3>
                <div>
                  <StructuredText data={results} />
                </div>
              </>
            )}
          </div>

          {(!!extraVideos?.length || !!extraImages?.length) && (
            <>
              <h2 className="text-3xl text-center mb-4">
                <FormattedMessage id="detailExtra" />
              </h2>
              <Extra videos={extraVideos || []} images={extraImages || []} />
            </>
          )}

          <div className="hidden md:block text-center mt-5 font-roboto tracking-wide text-sm">
            {works.map((work) => (
              <Link
                key={work.id}
                to={localeSlug(langKey, `/works/${work.slug}`)}
                className={`inline-block border px-3 py-2 mr-2 mb-2 hover:bg-black hover:text-white ${
                  work.id === id ? "bg-black text-white" : ""
                }`}
              >
                {work.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Work($id: String!, $locale: String!, $slug: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    work: datoCmsWork(id: { eq: $id }, locale: { eq: $locale }) {
      id
      title
      brand
      isSpot
      youtubeVideo {
        providerUid
      }
      youtubeVideo2 {
        providerUid
      }
      youtubeVideo3 {
        providerUid
      }
      youtubeVideo4 {
        providerUid
      }
      challenge {
        value
      }
      idea {
        value
      }
      results {
        value
      }
    }
    extra: datoCmsWork(slug: { eq: $slug }, locale: { eq: $locale }) {
      extraVideos {
        url
        alt
        gatsbyImageData(layout: FULL_WIDTH)
        video {
          thumbJpg: thumbnailUrl(format: jpg)
        }
      }
      extraImages {
        title
        url
        croped: gatsbyImageData(
          imgixParams: { w: "400", h: "226", fit: "crop", q: 100 }
        )
      }
    }
    works: allDatoCmsWork(filter: { locale: { eq: $locale } }) {
      nodes {
        id
        title
        slug
      }
    }
  }
`;

export default Work;
